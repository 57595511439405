<template>
    <sidebar-menu :menu="menuItems" :collapsed="isCollapsed" :width="width" :widthCollapsed="widthCollapsed"
        :showOneChild="true" :showChild="false" :rtl="false" :relative="false" :hideToggle="hideToggle"
        :theme="darkTheme ? '' : 'white-theme'" :disableHover="false" @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick">
        <div slot="header">
            <div class="pt-4 pb-3">
                <b-row no-gutters class="align-items-center justify-content-center">
                    <div class="col-4 d-flex justify-content-center" v-if="!isCollapsed">
                        <SiteLogo height="60px" />
                    </div>
                    <div v-else>
                        <fa icon="fa-solid fa-paper-plane" class="text-primary"
                            :style="{ fontSize: isCollapsed ? '32px' : '56px' }" />
                    </div>
                </b-row>
            </div>
        </div>
        <!-- <div slot="footer">footer</div> -->
        <span slot="toggle-icon" class="text-muted" style="font-size: 1.5rem" v-b-tooltip.hover="{ variant: 'light' }"
            :title="isCollapsed ? 'Open menu' : 'Collapse menu'">
            <font-awesome-icon icon="fa-solid fa-circle-chevron-right" v-if="isCollapsed" />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-left" v-else />
        </span>
        <span slot="dropdown-icon">
            <font-awesome-icon icon="fa-solid fa-angle-down" />
        </span>
    </sidebar-menu>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
export default {
    components: {
        SidebarMenu,
    },
    props: ["width", "widthCollapsed"],
    filters: {},
    data() {
        return {
            darkTheme: false,
            menu: [
                {
                    href: { name: "dashboard.overview" },
                    title: "Dashboard",
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-house",
                        },
                    },
                },
                {
                    header: true,
                    title: "General",
                    ability: ["index", "post"],
                    class: "text-muted text-capitalize",
                    hiddenOnCollapse: true,
                },
                {
                    title: "Posts",
                    ability: ["index", "post"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-thumbtack",
                        },
                    },
                    child: [
                        {
                            href: { name: "posts.all", path: "" },
                            title: "All Posts",
                        },
                        {
                            href: { name: "posts.add", path: "" },
                            title: "Add New",
                        },
                    ],
                },
                {
                    // href: { name: "articles.all" },
                    title: "Articles",
                    ability: ["index", "article"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-pen-clip",
                        },
                    },
                    child: [
                        {
                            href: { name: "articles.all", path: "" },
                            title: "All Articles",
                        },
                        {
                            href: { name: "articles.add", path: "" },
                            title: "Add New",
                        },
                    ],
                },
                {
                    title: "Paper Works",
                    ability: ["index", "paper"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-file-lines",
                        },
                    },
                    child: [
                        {
                            href: { name: "papers.all" },
                            title: "All Papers",
                        },
                        {
                            href: { name: "papers.add" },
                            title: "Add New",
                        },
                    ],
                },
                {
                    title: "Projects",
                    ability: ["index", "project"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-diagram-project",
                        },
                    },
                    child: [
                        {
                            href: { name: "projects.all" },
                            title: "All Projects",
                        },
                        {
                            href: { name: "projects.add" },
                            title: "Add New",
                        },
                    ],
                },
                {
                    header: true,
                    title: "Meta",
                    class: "text-muted text-capitalize",
                    hiddenOnCollapse: true,
                    ability: ["index", "file"],
                },
                {
                    href: { name: "dashboard.media" },
                    title: "Files and Media",
                    ability: ["index", "file"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-photo-film",
                        },
                    },
                },
                {
                    href: { name: "dashboard.comments" },
                    title: "Comments",
                    ability: ["index", "comment"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-comment",
                        },
                    },
                },
                {
                    href: { name: "dashboard.categories" },
                    title: "Categories",
                    ability: ["index", "term"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-folder-tree",
                        },
                    },
                },
                // {
                //     title: "Taxonomy",
                //     ability: ["index", "term"],
                //     icon: {
                //         element: "font-awesome-icon",
                //         class: "",
                //         attributes: {
                //             icon: "fa-solid fa-folder-tree",
                //         },
                //     },
                //     child: [
                //         {
                //             href: { name: "dashboard.categories" },
                //             title: "Categories",
                //         },
                //         {
                //             href: { name: "dashboard.tags" },
                //             title: "Tags",
                //         },
                //     ],
                // },
                {
                    header: true,
                    title: "Others",
                    ability: ["index", "post"],
                    class: "text-muted text-capitalize",
                    hiddenOnCollapse: true,
                },
                {
                    href: { name: "users.profile" },
                    title: "My Profile",
                    ability: ["edit", "profile"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-user",
                        },
                    },
                },
                {
                    title: "Members",
                    href: { name: "users.all", path: "" },
                    ability: ["index", "user"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-users",
                        },
                    },
                },
                // {
                //     title: "Certificate",
                //     ability: ["index", "certificate"],
                //     href: { name: "certificates.all" },
                //     icon: {
                //         element: "font-awesome-icon",
                //         class: "",
                //         attributes: {
                //             icon: "fa-solid fa-stamp",
                //         },
                //     },
                //     child: [
                //         {
                //             href: { name: "certificates.all" },
                //             title: "All Mails",
                //         },
                //         {
                //             href: { name: "certificate.add" },
                //             title: "Add New",
                //         },
                //     ],
                // },
                {
                    title: "Public Response",
                    href: { name: "dashboard.response" },
                    ability: ["index", "contact"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-phone",
                        },
                    },
                },
                {
                    title: "Mailer",
                    ability: ["index", "mail"],
                    href: { name: "mails.all" },
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-envelope",
                        },
                    },
                    child: [
                        {
                            href: { name: "mails.all" },
                            title: "All Mails",
                        },
                        {
                            href: { name: "mail.send" },
                            title: "Send",
                        },
                    ],
                },
                {
                    href: { name: "dashboard.settings" },
                    title: "Settings",
                    ability: ["index", "settings"],
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-gear",
                        },
                    },
                },
                {
                    href: { name: "dashboard.about" },
                    title: "About",
                    icon: {
                        element: "font-awesome-icon",
                        class: "",
                        attributes: {
                            icon: "fa-solid fa-info",
                        },
                    },
                },
            ],
        };
    },
    computed: {
        isCollapsed() {
            return this.$store.getters.getIsAdminSidebarCollapsed;
            // return true;
        },
        hideToggle() {
            // let isSmall = window.matchMedia ? window.matchMedia("screen and (max-width: 576px)") : screen.width <= 576;
            let isSmall = screen.width <= 576;

            return isSmall;
        },

        menuItems() {
            // return this.menu;

            return this.menu.filter((item) => {
                if (item["ability"]) {
                    if (this.$can(item.ability[0], item.ability[1])) {
                        return true;
                    }
                } else {
                    return true;
                }
            });
        },

        canI() {
            return !!this.$can("sdf", "fsdf");
        },
    },
    methods: {
        onToggleCollapse(collapsed) {
            this.$store.commit("TOOGLE_ADMIN_SIDEBAR", collapsed);
        },
        // onItemClick(event, item, node) {},
        onItemClick() { },
    },
};
</script>

<style lang="scss">
.vsm--link_mobile-item {
    padding-top: 14px !important;

    .vsm--title {
        color: $light;
    }
}

.vsm--header {
    color: $gray-500 !important;
}

// // ================ vue-sidebar-menu =============
$primary-color: $primary !default;
$base-bg: #2a2a2e !default;

$item-color: #fff !default;

$item-active-color: null !default;
$item-active-bg: null !default;

$item-open-color: #fff !default;
$item-open-bg: $primary-color !default;

$item-hover-color: null !default;
$item-hover-bg: rgba(darken($base-bg, 5%), 0.5) !default;

$icon-color: $gray-600 !default;
$icon-bg: rgb(243, 243, 243) !important;

$icon-active-color: $primary-color !important;
$icon-active-bg: $primary-color !default;

$icon-open-color: $primary-color !default;
$icon-open-bg: $item-open-color !default;

$mobile-item-color: $primary-color !default;
$mobile-item-bg: $primary-color !default;
$mobile-icon-color: $mobile-item-color !default;
$mobile-icon-bg: $mobile-item-bg !default;

$dropdown-bg: lighten($base-bg, 5%) !default;
$dropdown-color: null !default;

$item-font-size: 14px !default;
$item-line-height: 30px !default;
$item-padding: 6px !default;
$icon-height: 18px !default;
$icon-width: $icon-height !default;

// ================ vue-sidebar-menu =============
@import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";

// ================ vue-sidebar-menu class overwrite =============
.v-sidebar-menu {
    // position: relative !important;
    // min-height: 100vh !important;
    // max-height: 100vh !important;
    // height: 100% !important;

    .vsm--scroll-wrapper {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        padding-bottom: 50px;
    }

    .vsm--scroll-wrapper::-webkit-scrollbar {
        display: none !important;
    }

    .vsm--link {
        &_level-1 {
            display: flex;
            align-items: center;

            .vsm--icon {
                margin-left: 5px;
                padding: 8px !important;
                border-radius: 5px !important;
                // background-color: $gray-300 !important;
            }
        }
    }

    .vsm--dropdown {
        background-color: white !important;
        box-shadow: $box-shadow-sm;
    }

    .vsm--dropdown>.vsm--list {
        background-color: transparent !important;
    }

    // .vsm--mobile-item{
    // 	padding-top: 0px !important;
    // }
}
</style>