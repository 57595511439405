import axios from "../../plugins/axios";
import { AbilityBuilder, Ability } from "@casl/ability";
import ability from '../../services/ability';
import Vue from 'vue';
export default {
    namespaced: true,

    state: {
        idToken: null,
        user: null,

        defaultRoles: ['subscriber', 'author', 'editor', 'admin', 'super admin'],
    },

    mutations: {
        SET_AUTH_TOKENS(state, token) {
            localStorage.setItem("idToken", token);
            state.idToken = token;
        },

        SET_CURRENT_USER(state, user) {
            // state.first_name = user.first_name;
            state.user = user
        },

        CLEAR_AUTH_STATUS(state) {
            Vue.$log.info('CLEAR_AUTH_STATUS');
            localStorage.removeItem("idToken");
            state.idToken = null;
        },

        UPDATE_USER_ABILITY(state, user) {
            const { can, rules } = new AbilityBuilder(Ability);
            user.roles.forEach(role => {
                if (role.name === 'super admin') {
                    can('manage', 'all');
                } else {
                    role.permissions.forEach(permission => {
                        let perm = permission.name.split('.');
                        if (perm[2]) {
                            /**
                             * for example : approve.post.other -> can('approve-other', 'post')
                             */
                            can(`${perm[0]}-${perm[2]}`, perm[1]);
                        } else {
                            can(perm[0], perm[1]);
                        }
                    })
                }
            });
            ability.update(rules);
        },
    },

    actions: {
        /**
         * The methods for new account registration.
         * 
         * @param {Object} commit The commit object of vuex store
         * @param {Object} body New account credentials
         * @returns {Promise}
         */
        register(context, body) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/register", body)
                    .then((res) => {
                        Vue.$log.info(res);
                        // if (res.data.status === 'success') {
                        // if (res.data.status === 'success') {
                        //     commit("SET_AUTH_TOKENS", res.data.token);
                        //     commit("SET_CURRENT_USER", res.data.user);
                        // }
                        // }
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        /**
         * The method to logging in an user.
         * 
         * @param {Object} commit The commit object of vuex store
         * @param {Object} body Login credentials
         * @returns {Promise}
         */
        login({ commit }, body) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/login", body)
                    .then((res) => {
                        Vue.$log.info(res);
                        if (res.data.status === "success") {
                            commit("SET_AUTH_TOKENS", res.data.token);
                            commit("SET_CURRENT_USER", res.data.user);
                            commit("UPDATE_USER_ABILITY", res.data.user);
                        }
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        /**
         * Logout user and removes auth tokens.
         * 
         * @param {*} param0 
         * @param {*} redirectTo 
         * @returns 
         */
        logout({ commit }, redirectTo = '/login') {
            return new Promise(() => {
                axios.post('/logout').then(() => {
                    commit("CLEAR_AUTH_STATUS");
                }).catch((err) => {
                    Vue.$log.info('logout res', err);
                    commit('CLEAR_AUTH_STATUS');
                    alert('Your authentication has expired!');
                }).finally(() => {
                    window.location.href = redirectTo;
                })
            });
        },

        /**
         * Validates user's auth token.
         * 
         * @param {Object} context 
         * @returns 
         */
        identifyUser({ commit, getters }, redirect) {
            return new Promise((resolve, reject) => {
                axios.post("/auth/validate").then((res) => {
                    if (res) {
                        commit("SET_AUTH_TOKENS", getters.getAuthToken);
                        commit("SET_CURRENT_USER", res.data.user);
                        commit("UPDATE_USER_ABILITY", res.data.user);
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }).catch((err) => {
                    Vue.$log.info(err);
                    commit("CLEAR_AUTH_STATUS");
                    window.location.href = redirect;
                }
                );
            })
        },

        /**
         * The method to recover account of an user.
         * 
         * @param {Object} commit The commit object of vuex store
         * @param {Object} body account credentials
         * @returns {Promise}
         */
        forgotPassword(context, body) {
            Vue.$log.info('forgotPassword action', body)
            return new Promise((resolve, reject) => {
                axios
                    .post("/forgot-password", body)
                    .then((res) => {
                        if (res.data.status == 'success') {
                            resolve(res.data);
                        } else {
                            reject(res.data);
                        }
                    })
                    .catch((err) => err);
            });
        },

        /**
         * The method to reset user account password.
         * 
         * @param {Object} commit The VueX commit
         * @param {Object} body account credentials
         * @returns {Promise}
         */
        resetPassword({commit}, body) {
            Vue.$log.info('resetPassword action', body)
            return new Promise((resolve, reject) => {
                axios
                    .post('reset-password/', body)
                    .then((res) => {
                        if (res.data.status == 'success') {
                            resolve(res.data);
                            commit('CLEAR_AUTH_STATUS');
                        } else {
                            reject(res.data);
                        }
                    })
                    .catch((err) => err);
            });
        },
    },

    getters: {
        getAuthToken(state) {
            if (state.idToken) {
                return state.idToken;
            } else if (localStorage.getItem('idToken') != null) {
                return localStorage.getItem('idToken');
            }
            return null;
        },

        isAuthenticated(state, getters) {
            return !!getters.getAuthToken;
        },

        getAuthenticatedUser(state) {
            return state.user;
        },

        getUserRoles(state) {
            let roles = [];
            state.user.roles.forEach(role => {
                roles.push(role.name);
            });
            // Vue.$log.info(roles);

            return roles;
        },

        getDefaultRoles(state) {
            return state.defaultRoles;
        },

        getUserPermissions(state) {
            let permissions = [];
            state.user.roles.forEach(role => {
                role.permissions.forEach(permission => {
                    let perm = permission.name.split('.');
                    permissions.push({
                        action: perm[1],
                        subject: perm[0] || '',
                        // inverted: false,
                        // conditions: {},
                    });
                })
            });
            // Vue.$log.info(permissions);

            // Vue.UPDATE_USER_ABILITY(permissions);

            return permissions;
        }
    },
};
