<template>
    <b-navbar toggleable="lg" type="light" class="shadow-sm custom-navbar">
        <div class="container">
            <b-navbar-brand :to="{ name: 'home' }">
                <SiteLogo />
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav small class="ml-auto">
                    <b-nav-item
                        v-for="(link, i) in links.public"
                        :key="i"
                        :to="{ name: link.route }"
                        :active="link.route === currentRouteName"
                        class="my-auto text-uppercase mx-1"
                        >{{ link.title }}
                    </b-nav-item>

                    <!-- <b-nav-item-dropdown right no-caret v-if="isAuthenticated">
                        <template #button-content class="d-flex align-items-centerjustify-content-center">
                            <b-avatar
                                variant="primary"
                                :src="currentUser.user_profile.profile_pic_url"
                                :text="processName(currentUser)[0]"
                                size="2rem"
                                class="mr-2"
                            ></b-avatar>
                        </template>
                        <b-dropdown-item
                            v-for="(link, i) in links.auth"
                            :key="i"
                            :to="{ name: link.route }"
                            >{{ link.title }}</b-dropdown-item
                        >
                        <b-dropdown-item @click="logout"
                            >Logout</b-dropdown-item
                        >
                    </b-nav-item-dropdown> -->
                    <b-nav-item-dropdown no-caret v-if="isAuthenticated">
                        <!-- Using 'button-content' slot -->
                        <template #button-content>
                            <b-avatar
                                :src="currentUser.user_profile.profile_pic_url"
                                :text="processName(currentUser)[0]"
                                size="2.5rem"
                                variant="primary"
                            ></b-avatar>
                        </template>
                        <b-dropdown-text style="min-width: 200px">
                            <h6 class="mb-0">{{ processName(currentUser) }}</h6>
                            <span class="text-small">{{
                                currentUser.email
                            }}</span>
                        </b-dropdown-text>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            :to="{ name: 'users.profile' }"
                            class="text-small"
                        >
                            <div class="py-2">
                                <font-awesome-icon
                                    icon="fa-regular fa-user"
                                    class="mr-2"
                                />
                                My Profile
                            </div>
                        </b-dropdown-item>
                        <!-- <b-dropdown-item :to="{ name: 'login' }"> Login </b-dropdown-item> -->
                        <b-dropdown-item
                            :to="{ name: 'dashboard.overview' }"
                            class="text-small"
                        >
                            <div class="py-2">
                                <font-awesome-icon
                                    :icon="['fab', 'trello']"
                                    class="mr-2"
                                />
                                Dashboard
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="logout" class="text-small">
                            <div class="py-2">
                                <font-awesome-icon
                                    icon="fa-solid fa-power-off"
                                    class="mr-2"
                                />Logout
                            </div>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
import { processName } from "@/utils/utilities";
export default {
    name: "default-navbar",
    components: {},
    data() {
        return {
            processName,
            links: {
                public: [
                    { route: "home", title: "Home" },
                    { route: "feed", title: "Feed" },
                    { route: "articles", title: "Articles" },
                    { route: "about", title: "About" },
                    { route: "members.all", title: "Members" },
                    { route: "alumni.all", title: "Alumni" },
                    { route: "hallOfFame", title: "Hall Of Fame" },
                    { route: "contact", title: "Contact" },
                    // { route: "brainiac", title: "Brainiac" },
                ],
                auth: [
                    { route: "dashboard.overview", title: "Dashboard" },
                    { route: "users.profile", title: "My Profile" },
                    { route: "forgotPassword", title: "Recover Account" },
                ],
            },
        };
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters["auth/isAuthenticated"];
        },
        currentUser() {
            return this.$store.getters["auth/getAuthenticatedUser"];
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("auth/logout");
        },
    },
};
</script>

<style lang="scss">
.custom-navbar {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
}

.nav-item {
    .nav-link.router-link-exact-active {
        color: $primary !important;
    }
}
</style>