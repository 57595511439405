<template>
    <b-navbar toggleable="lg" variant="white" class="shadow-sm position-sticky" style="z-index: 999; top: 0px">
        <b-navbar-brand href="#" class="d-md-inline-block d-none">
            <fa icon="fa-solid fa-bars" @click="toggleSidebar" />
        </b-navbar-brand>
        <b-navbar-nav>
            <b-nav-text class="ml-2">
                <h5 class="text-dark text-uppercase p-0 m-0">
                    {{ currentPageTitle }}
                </h5>
            </b-nav-text>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto row align-items-center">
            <!-- <span @click="notification" v-b-tooltip.hover.v-light title="Notifications"
                class="text-dark cursor-pointer mx-3 d-md-inline-block d-none">
                <fa icon="fa-regular fa-bell" />
            </span> -->
            <span @click="fullscreen" v-b-tooltip.hover.v-light title="Toggle Fullscreen (F11)"
                class="text-dark cursor-pointer mx-3 d-md-inline-block d-none">
                <fa icon="fa-solid fa-expand" />
            </span>
            <b-nav-item-dropdown right no-caret class="mr-3">
                <!-- Using 'button-content' slot -->
                <template #button-content>
                    <b-avatar :src="currentUser.user_profile.profile_pic_url" :text="processName(currentUser)[0]" size="2.5rem" variant="primary"></b-avatar>
                </template>
                <b-dropdown-text style="min-width: 200px;">
                    <h6 class="mb-0">{{processName(currentUser)}}</h6>
                    <span class="text-small">{{ currentUser.email }}</span>
                </b-dropdown-text>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'users.profile' }" class="text-small">
                    <div class="py-2">
                        <font-awesome-icon icon="fa-regular fa-user" class="mr-2" /> My Profile
                    </div>
                </b-dropdown-item>
                <!-- <b-dropdown-item :to="{ name: 'login' }"> Login </b-dropdown-item> -->
                <b-dropdown-item :to="{ name: 'home' }" class="text-small">
                    <div class="py-2">
                        <font-awesome-icon icon="fa-solid fa-house" class="mr-2" /> Home
                    </div>
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'feed' }" class="text-small">
                    <div class="py-2">
                        <font-awesome-icon icon="fa-solid fa-rss" class="mr-2" /> News Feed
                    </div>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout" class="text-small">
                    <div class="py-2">
                        <font-awesome-icon icon="fa-solid fa-power-off" class="mr-2" />Logout
                    </div>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
import { toggleFullscreen, processName } from "@/utils/utilities.js"
export default {
    name: "dashboard-navbar",
    data() {
        return {
            processName,
            sidebarExpanded: true,
        };
    },
    methods: {
        toggleSidebar() {
            this.$store.commit('TOOGLE_ADMIN_SIDEBAR', !this.$store.getters.getIsAdminSidebarCollapsed);
        },

        fullscreen() {
            toggleFullscreen();
        },

        notification() {

        },

        logout() {
            this.$store.dispatch("auth/logout");
        },
    },
    computed: {
        currentPageTitle() {
            let title = this.$route.meta.pageName || this.$route.name
            return title;
        },

        currentUser() {
            return this.$store.getters['auth/getAuthenticatedUser'];
        },

        getUser() {
            return this.$store.getters['auth/getAuthenticatedUser'];
        }
    }
};
</script>

<style scoped>

</style>